import SectionTitle from "../../shared/sectionTitle";
import {Trash} from "iconoir-react";
import Button from "../../shared/button";
import {useEffect, useState} from "react";

export default function CategoriesTable(props) {
  const {
    fp,
    onCategoryAdd,
    onCategoryChange,
    onCategoryDelete,
  } = props;

  const [categories, setCategories] = useState(fp.categories || []);

  function sortCategories() {
    setCategories([...fp.categories || []].sort((a, b) => {
      if (!b.name) {
        return -1;
      }
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    }));
  }

  function setCatsFromFP() {
    if ((fp.categories || []).length !== categories.length) {
      sortCategories();
    }
  }

  useEffect(() => {
    sortCategories();
  }, []);

  useEffect(() => {
    setCatsFromFP();
  }, [fp]);

  if (!fp) {
    return null;
  }

  return (
    <div>
      <SectionTitle>
        Catégories
      </SectionTitle>

      {(!categories || categories.length === 0) ? <div className="text-gray-500">
          Pas de catégories
        </div> :
        <div>
          <table className="border-collapse border border-slate-400 bg-white text-sm shadow-sm">
            <thead>
            <tr>
              <th className="border border-slate-300 bg-slate-50 font-semibold p-3 text-slate-900 text-left"></th>
              <th style={{minWidth: '220px'}} className="border border-slate-300 bg-slate-50 font-semibold p-3 text-slate-900 text-left">
                Nom
              </th>
            </tr>
            </thead>
            <tbody>
            {categories.map((category, i) => {
                return (
                  <tr key={i}>
                    <th
                      onClick={() => onCategoryDelete(category.id)}
                      className="cursor-pointer border border-slate-300 px-2 text-red-900 text-center">
                      <Trash width={15}/>
                    </th>
                    <td className="border border-slate-300 px-2 py-1">
                      <input type="text"
                             value={category.name}
                             placeholder="Nom de la catégorie"
                             className="w-full"
                             onChange={(e) => onCategoryChange(category.id, {name: e.target.value})}
                      />
                    </td>
                  </tr>
                )
              }
            )}
            </tbody>
          </table>
        </div>
      }

      <div className="mt-3">
        <Button
          type="success"
          onClick={onCategoryAdd}>
          + Ajouter
        </Button>
      </div>
    </div>
  );
}