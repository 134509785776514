

export const db = {

  // This is the database object. It is used to store data in the browser's local storage.
  // It is used to store the user's financial plan.

  storeFPToLocalstorage: function (fp) {
    // localStorage.setItem(`financial_plan_${fp.id}`, JSON.stringify(fp));
    localStorage.setItem(`financial_plan_1`, JSON.stringify(fp));
  },
  getFPFromLocalStorage: function (fpId) {
    try {
      return JSON.parse(localStorage.getItem(`financial_plan_1`) || {});
    } catch(err) {
      return null;
    }
  }


};