
export default function formatDate(date, separator = '.') {
  if (!date) {
    date = new Date();
  }
  date = new Date(date);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${day < 10 ? '0' + day : day}${separator}${month < 10 ? '0' + month : month}${separator}${year}`;
}