
const exampleData = {
  version: "0.1",
  financialPlan: {
    id: 1,
    name: "Plan financier",
    currency: "CHF"
  },
  items: [

  ],
  periods: [

  ],
  ranges: []
}

export default exampleData;
