import {Trash} from "iconoir-react";
import {useEffect, useState} from "react";

export default function RevenueItem(
  {
    fp,
    revenue,
    onRevenueChange,
    onRevenueDelete,
  }) {

  const [revenueAmountLocal, setRevenueAmountLocal] = useState(revenue.amount);

  useEffect(() => {
    setRevenueAmountLocal(revenue.amount);
  }, [fp]);

  return (
    <tr>
      <th
        onClick={() => onRevenueDelete(revenue.id)}
        className="cursor-pointer border border-slate-300 px-2 text-red-900 text-center">
        <Trash width={15}/>
      </th>
      <td className="border border-slate-300 px-2 py-1">
        <input
          value={revenue.date}
          onChange={(e) => onRevenueChange(revenue.id, {date: e.target.value})}
          type="date"
          className="border border-gray-300 rounded-md"
          name="dateStart" id="dateStart" />
      </td>
      <td className="border border-slate-300 px-2 py-1">
        <input type="text"
               value={revenue.name}
               placeholder="Nom du revenu"
               className="w-full"
               onChange={(e) => onRevenueChange(revenue.id, {name: e.target.value})}
        />
      </td>
      <td className="border border-slate-300 px-2 py-1 text-slate-500 text-right">
        <input type="text"
               style={{width: '80px'}}
               className="py-1 text-right"
               onChange={(e) => setRevenueAmountLocal(e.target.value)}
               onBlur={(e) => onRevenueChange(revenue.id, {amount: revenueAmountLocal})}
               value={revenueAmountLocal ?? ''}/>

      </td>
    </tr>
  )
}