import {db} from "../../localDB";
import useAlert from "../../utils/useAlert";
import Button from "./button";
import formatDate from "../../utils/formatDate";

export default function Navbar() {
  const { setAlert } = useAlert();

  function saveToFile() {
    const fp = db.getFPFromLocalStorage();
    if (!fp) {
      alert('Aucun plan financier à sauvegarder.');
      return;
    }
    const json = JSON.stringify(fp);
    const blob = new Blob([json], {type: 'application/json'});
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    const id = fp.financialPlan ? fp.financialPlan.id : '1';
    const today = new Date();
    link.download = `${today.toISOString().split('T')[0]} plan financier_${id}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function alertBadFile() {
    setAlert("Le fichier ne contient pas un plan financier valide.", "green");
  }

  function onFileChange(e) {
    if (!e || !e.target || !e.target.files || e.target.files.length === 0) {
      console.log('No file to import');
      return;
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      try {
        const text = e.target.result;
        const fp = JSON.parse(text);
        if (!fp || !fp.version || !fp.financialPlan || !fp.financialPlan.id || !fp.items || !fp.ranges ) {
          alertBadFile();
          return;
        }
        db.storeFPToLocalstorage(fp);
        setAlert("Le fichier a été chargé avec succès, la page va maintenant se rafraîchir", "green");
        setTimeout(() => {
          window.location.reload();
        }, 2500);
        console.log(fp);
      } catch(err) {
        console.error(err);
        alertBadFile();
      }
    }
    reader.onerror = function (e) {
      console.error(e);
      alertBadFile();
    }
    reader.readAsText(file);
  }

  return (
    <nav className="bg-white border-gray-200 px-2 sm:px-4 rounded">
      <div
        style={{paddingLeft: 0, paddingRight: 0}}
        className="container flex flex-wrap justify-between items-center mx-auto">

        <div className="w-full" id="navbar-default">
          <ul
            className="flex flex-col p-4 mt-4 bg-gray-50 rounded-lg border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
            <li>
              <label htmlFor="file-import"
                     className="inline-block mb-2 mr-4 px-4 py-2 font-semibold bg-slate-500 text-white rounded-full shadow-sm">
                Importer un fichier
              </label>
              <input id="file-import"
                     type="file"
                     onChange={onFileChange}
                     accept=".json"
                     className="hidden"/>

              <Button
                onClick={saveToFile}
                type="success"
              >
                Exporter en fichier
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}