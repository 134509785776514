import Button from "../../shared/button";
import Modal from "../../shared/modal";
import {useMemo, useState} from "react";
import getDayName from "../../../utils/getDayName";
import useAlert from "../../../utils/useAlert";
import periodDatesOK from "../../../utils/periodDatesOK";

export function EditPeriodModal(props) {
  const {
    period,
    cancel,
    onChange,
  } = props;

  const [startDate, setStartDate] = useState(new Date(period.dateStart));
  const [endDate, setEndDate] = useState(new Date(period.dateEnd));
  const { setAlert } = useAlert();


  const daysOfWeek = useMemo(() => {
    return [
      startDate ? getDayName(startDate) : '',
      endDate ? getDayName(endDate) : '',
    ]
  }, [startDate, endDate]);

  function editPeriod() {
    const newStart = new Date(startDate);
    const newEnd = new Date(endDate);

    if (!periodDatesOK(startDate, endDate)) {
      setAlert("Les dates ne sont pas correctes", "red");
      return;
    }
    onChange({
      dateStart: newStart.toISOString().split('T')[0],
      dateEnd: newEnd.toISOString().split('T')[0],
    });
  }

  const nbDays = useMemo(() => {
    if (!startDate || !endDate) {
      return 0;
    }
    return Math.max(0, Math.floor((new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24)) + 1);
  }, [startDate, endDate]);

  return (
    <Modal
      visible={true}
      close={cancel}
      title={"Modifier la période"}
      body={
        <div className="text-left">
          <div className="flex gap-3 text-left items-center">
            <div>
              Du <br/>
              <input
                value={startDate ? new Date(startDate).toISOString().split('T')[0] : ''}
                onChange={(e) => setStartDate(e.target.value)}
                type="date"
                className="border-solid border-2 border-gray-300 rounded-md"
                name="dateStart" id="dateStart" />
              <div className="text-slate-400 text-sm">
                {daysOfWeek[0] || <span>&nbsp;</span>}
              </div>
            </div>

            <div className="">
              Au <br/>
              <input
                value={endDate ? new Date(endDate).toISOString().split('T')[0] : ''}
                onChange={(e) => setEndDate(e.target.value)}
                type="date"
                className="border-solid border-2 border-gray-300 rounded-md"
                name="dateEnd" id="dateEnd" />

              <div className="text-slate-400 text-sm">
                {daysOfWeek[1] || <span>&nbsp;</span>}
              </div>
            </div>

            <div>
              {nbDays} jour{nbDays > 1 ? 's' : ''}
            </div>
          </div>

        </div>}

      footer={
        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
          <Button
            onClick={cancel}
            className="mr-2"
          >
            Annuler
          </Button>
          <Button
            type="standard"
            onClick={() => editPeriod(startDate, endDate)}>
            Sauvegarder
          </Button>
        </div>}
    >
    </Modal>
  )
}