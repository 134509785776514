import SORTORDER from "../../constants/sortOrder";


export function SortIcons(props) {
  const {
    sortKey,
    active,
    order=SORTORDER.ASC,
    onSorterClick,
  } = props;

  return (
    <span className="ml-1 mr-1">
      <span
        onClick={() => onSorterClick(sortKey, SORTORDER.ASC)}
        className={`cursor-pointer hover:text-cyan-300 ${(active && order === SORTORDER.ASC) ? 'text-cyan-500' : 'text-slate-700'}`}>&#8593;</span>
      <span
        onClick={() => onSorterClick(sortKey, SORTORDER.DESC)}
        className={`cursor-pointer hover:text-cyan-300 ${(active && order === SORTORDER.DESC) ? 'text-cyan-500' : 'text-slate-700'}`}>&#8595;</span>
    </span>
  )
}