import {useEffect, useMemo, useRef, useState} from "react";
import {SortIcons} from "../../shared/sortIcons";
import SORTORDER from "../../../constants/sortOrder";
import {Cancel, Trash} from "iconoir-react";
import Button from "../../shared/button";
import BillItem from "./billItem";
import getValidNb from "../../../utils/getValidNb";
import round2 from "../../../utils/round2";

const PAIDFILTERS = {
  ALL: 'all',
  PAID: 'paid',
  UNPAID: 'unpaid',
};

const SORTBY = {
  ID: 'id',
  NAME: 'name',
  AMOUNT: 'amount',
  DUE_DATE: 'dueDate',
}

export default function Bills(props) {
  const {
    fp,
    setFp,
    period=null,
    billsItemFilter,
    setBillsItemFilter,
    scrollable=false,
  } = props;
  const scrollableDiv = useRef();
  const [maxDate, setMaxDate] = useState(period ? period.dateEnd : null);
  const [paidFilter, setPaidFilter] = useState(PAIDFILTERS.UNPAID);
  const [showArchivedFilter, setShowArchivedFilter] = useState(false); // show archived or not
  const [sort, setSort] = useState(SORTBY.DUE_DATE);
  const [sortOrder, setSortOrder] = useState(SORTORDER.DESC);

  const [bills, setBills] = useState([...fp.bills] || []);
  const items = [...fp.items || []].sort((a, b) => a.name.localeCompare(b.name));
  const [billsToKeepInList, setBillsToKeepInList] = useState([]);


  const filteredBills = useMemo(() => {
    return bills.filter(bill => {
      if (!showArchivedFilter && bill.archived) {
        return false;
      }
      if (paidFilter === PAIDFILTERS.ALL && !billsItemFilter) {
        return true;
      }
      //const dateIsInRange = !bill.dueDate || bill.dueDate <= maxDate;
      const dateIsInRange = true;
      const paidFilterOK = paidFilter === PAIDFILTERS.ALL
        || (paidFilter === PAIDFILTERS.PAID && bill.paid)
        || (paidFilter === PAIDFILTERS.UNPAID && !bill.paid)
        || billsToKeepInList.includes(bill.id);

      const billsItemOK = !billsItemFilter || Number(bill.item) === Number(billsItemFilter);

      return dateIsInRange && paidFilterOK && billsItemOK;
    });
    /*
    .sort((a, b) => {
      const orderSign = sortOrder === SORTORDER.ASC ? -1 : 1;
      if (sort === SORTBY.NAME) {
        return (a.name < b.date ? -1 : 1) * orderSign;
      } else if (sort === SORTBY.AMOUNT) {
        return (a.amount - b.amount) * orderSign;
      } else if (sort === SORTBY.DUE_DATE) {
        return (new Date(a.dueDate) - new Date(b.dueDate)) * orderSign;
      }
      return 1;
    });
     */
  }, [maxDate, bills, paidFilter, sort, sortOrder, billsItemFilter, showArchivedFilter]);

  useEffect(() => {
    sortByField(sort, sortOrder);
  }, []);

  function onBillChange(billId, field, newValue) {
    const newBills = bills.map(bill => {
      if (bill.id && bill.id === billId) {
        if (field === 'amount') {
          newValue = getValidNb(newValue);
          if (newValue === null) {
            newValue = "";
          } else {
            newValue = round2(newValue, true);
          }
        }
        // Keep the bill in the list even if filter would filter it out
        if (field === 'paid' && paidFilter !== PAIDFILTERS.ALL && !billsToKeepInList.includes(billId)) {
          setBillsToKeepInList([...billsToKeepInList, billId]);
        }

        if (field === 'dueDate') {
          setSort(SORTBY.ID);
        }
        return {
          ...bill,
          [field]: newValue,
        }
      }
      return bill;
    });
    setFp({
      ...fp,
      bills: newBills,
    });
    setBills(newBills);
  }

  function onAdd() {
    const newId = bills.reduce((max, bill) => {
      return bill.id > max ? bill.id : max;
    }, 0) + 1;

    const newBills = [
      ...bills,
      {
        id: newId,
        name: "",
        amount: 0,
        dueDate: "",
        paid: false,
        archived: false,
        paidDate: "",
        item: billsItemFilter ? billsItemFilter : null,
      },
    ];
    setFp({
      ...fp,
      bills: newBills,
    });
    setSort(SORTBY.ID);
    setBillsToKeepInList([...billsToKeepInList, newId]);
    setBills(newBills);
    setTimeout(() => {
      // Scroll to the bottom of the list
      if (scrollableDiv.current) {
        scrollableDiv.current.scrollTo({
          top: scrollableDiv.current.scrollHeight,
          behavior: 'smooth',
        });
        const nodes = scrollableDiv.current.querySelectorAll("input[name='bill-name']");
        const nameInput = nodes[nodes.length - 1];
        if (nameInput) {
          nameInput.focus();
        }
      }
    }, 500);
  }

  function sortByField(sortBy, newSortOrder) {
    setSort(sortBy);
    setSortOrder(newSortOrder);
    setBills([...bills].sort((a, b) => {
      return (a[sortBy] < b[sortBy] ? -1 : 1) * (newSortOrder === SORTORDER.ASC ? -1 : 1);
    }));
  }

  function onBillDelete(billId) {
    const newBills = bills.filter(bill => bill.id !== billId);
    setFp({
      ...fp,
      bills: newBills,
    });
    setBills(newBills);
  }

  function onPaidFilterChange(e) {
    setBillsToKeepInList([]);
    setPaidFilter(e.target.value);
  }

  function onShowArchivedChange(e) {
    setBillsToKeepInList([]);
    setShowArchivedFilter(e.target.checked);
  }

  return (
    <div>

      <div className="flex flex-row flex-wrap items-center mb-4 gap-4">
        {/*<div>
          Jusqu'au: <input type="date"
                           value={maxDate}
                           className="border border-gray-300 rounded-md"
                           onChange={e => setMaxDate(e.target.value)} />
        </div>*/}
        <div>
          Payées:
          <select
            value={paidFilter || PAIDFILTERS.ALL}
            onChange={onPaidFilterChange}
            className="border border-gray-300 ml-1 p-1 rounded-md">
            <option value={PAIDFILTERS.ALL}>Toutes</option>
            <option value={PAIDFILTERS.PAID}>Payées</option>
            <option value={PAIDFILTERS.UNPAID}>Non payées</option>
          </select>
        </div>

        <div>
          Lié à l'élément:
          <select
            value={billsItemFilter || ''}
            onChange={e => setBillsItemFilter(e.target.value)}
            style={{width: '200px'}}
            className="border border-gray-300 ml-1 p-1 rounded-md"
          >
            <option value="">
              -- Aucun --
            </option>
            {
              items.map(item => (
                  <option key={item.id} value={item.id}>{item.name}</option>
                )
              )
            }

          </select>
          {billsItemFilter &&
            <span
              onClick={() => setBillsItemFilter(null)}
              className="text-orange-600 ml-2 cursor-pointer">
              <Cancel/>
            </span>
          }
        </div>

        <div>
          <label htmlFor="show-archived">
            <input id="show-archived"
                   type="checkbox"
                   checked={showArchivedFilter}
                   onChange={onShowArchivedChange}
            />
            &nbsp; Montrer les archives
          </label>
        </div>
      </div>

      {filteredBills.length === 0 && <div className="text-gray-500">Pas de facture</div>}

      {filteredBills.length > 0 &&
        (
          <div ref={scrollableDiv} className="overflow-y-auto" style={{'maxHeight': scrollable ? '32rem' : null}}>
            <table className="border-collapse border border-slate-400 bg-white text-sm shadow-sm">
              <thead>
              <tr>
                <th className="border border-slate-300 bg-slate-50 font-semibold py-2 px-2 text-slate-900 text-left">
                </th>
                <th style={{minWidth: '220px'}}
                    className="border border-slate-300 bg-slate-50 font-semibold py-2 px-2 text-slate-900 text-left">
                  Nom
                </th>
                <th className="border border-slate-300 bg-slate-50 font-semibold py-2 px-2 text-slate-900 text-left">
                  Somme
                  {/*
              <SortIcons
                active={sort === SORTBY.AMOUNT}
                order={sortOrder}
                sortKey={SORTBY.AMOUNT}
                onSorterClick={sortByField}
              />
              */}
                </th>
                <th className="border border-slate-300 bg-slate-50 font-semibold py-2 px-2 text-slate-900 text-left">
                  Echéance
                  <SortIcons
                    active={sort === SORTBY.DUE_DATE}
                    order={sortOrder}
                    sortKey={SORTBY.DUE_DATE}
                    onSorterClick={sortByField}
                  />

                </th>
                <th className="border border-slate-300 bg-slate-50 font-semibold py-2 px-2 text-slate-900 text-left">
                  Payée
                </th>
                <th className="border border-slate-300 bg-slate-50 font-semibold py-2 px-2 text-slate-900 text-left">
                  Lié à
                </th>
                <th
                  style={{minWidth: '220px'}}
                  className="border border-slate-300 bg-slate-50 font-semibold py-2 px-2 text-slate-900 text-left">
                  Commentaires
                </th>
                <th
                  className="border border-slate-300 bg-slate-50 font-semibold py-2 px-2 text-slate-900 text-left">
                  Archivé
                </th>
              </tr>
              </thead>
              <tbody>
              {filteredBills.map((bill, i) => (
                  <BillItem
                    key={i}
                    fp={fp}
                    onBillChange={onBillChange}
                    onBillDelete={onBillDelete}
                    setFp={setFp}
                    bill={bill}
                    items={items || []}
                  />
                )
              )}
              </tbody>

            </table>
          </div>
        )
      }

      <div className="mt-3">
        <Button
          type="success"
          onClick={onAdd}>
          + Ajouter
        </Button>
      </div>
    </div>
  )
}