import PERIOD_TYPE from "../constants/periodType";
import round2 from "./round2";

export default function getItemCostsPerPeriod(item) {
  const cost = item.cost;

  const itemCostPerPeriod = {
    [PERIOD_TYPE.WEEK]: "",
    [PERIOD_TYPE.MONTH]: "",
    [PERIOD_TYPE.YEAR]: "",
  };

  if (cost === '') {
    itemCostPerPeriod[PERIOD_TYPE.WEEK] = '';
    itemCostPerPeriod[PERIOD_TYPE.MONTH] = '';
    itemCostPerPeriod[PERIOD_TYPE.YEAR] = '';
  } else if (item.periodType === PERIOD_TYPE.WEEK) {
    itemCostPerPeriod[PERIOD_TYPE.WEEK] = round2(cost, true);
    itemCostPerPeriod[PERIOD_TYPE.MONTH] = round2(cost * 52/12, true);
    itemCostPerPeriod[PERIOD_TYPE.YEAR] = round2(cost * 52, true);
  } else if (item.periodType === PERIOD_TYPE.MONTH) {
    itemCostPerPeriod[PERIOD_TYPE.WEEK] = round2(cost / (52/12), true);
    itemCostPerPeriod[PERIOD_TYPE.MONTH] = round2(cost, true);
    itemCostPerPeriod[PERIOD_TYPE.YEAR] = round2(cost * 12, true);
  } else if (item.periodType === PERIOD_TYPE.YEAR) {
    itemCostPerPeriod[PERIOD_TYPE.WEEK] = round2(cost / 52, true);
    itemCostPerPeriod[PERIOD_TYPE.MONTH] = round2(cost / 12, true);
    itemCostPerPeriod[PERIOD_TYPE.YEAR] = round2(cost, true);
  }

  return itemCostPerPeriod;
}
