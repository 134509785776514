import SectionTitle from "../../shared/sectionTitle";
import Button from "../../shared/button";
import RevenueItem from "./revenueItem";
import randomId from "../../../utils/randomId";
import getValidNb from "../../../utils/getValidNb";
import round2 from "../../../utils/round2";

export default function RevenuesTable(props) {
  const {revenues, fp, fpOnRange, setFp} = props;

  const revenuesSum = round2(revenues.reduce((sum, rev) => sum + parseFloat(rev.amount), 0), true);

  function onRevenueDelete(revenueId) {
    if (!fpOnRange) {
      return;
    }
    fpOnRange.revenues = fpOnRange.revenues.filter((revenue) => revenue.id !== revenueId);
    setFp({...fp});
  }

  function onRevenueAdd() {
    if (!fpOnRange) {
      return;
    }
    const newRevenue = {
      id: randomId(),
      date: new Date().toISOString().split('T')[0],
      name: "",
      amount: 0,
    };
    fpOnRange.revenues.push(newRevenue);
    setFp({...fp});
  }

  function onRevenueChange(id, partialRevenue) {
    if (!fpOnRange) {
      return;
    }
    const revenue = (fpOnRange.revenues || []).find((r) => (r.id === id));
    if (!revenue) {
      return;
    }
    if (partialRevenue.hasOwnProperty('amount')) {
      let amount = getValidNb(partialRevenue.amount);
      if (amount === null) {
        partialRevenue.amount = 0;
      } else if (amount) {
        partialRevenue.amount = round2(amount, true);
      }
    }
    Object.assign(revenue, partialRevenue);
    setFp({...fp});
  }

  return (
    <div>
      <SectionTitle>Revenus</SectionTitle>
      {(!revenues || revenues.length === 0) ? <div className="text-gray-500">
          Pas de revenu enregistré
      </div> :
        <div>
          <table className="border-collapse border border-slate-400 bg-white text-sm shadow-sm">
            <thead>
            <tr>
              <th className="border border-slate-300 bg-slate-50 font-semibold p-3 text-slate-900 text-left"></th>
              <th className="border border-slate-300 bg-slate-50 font-semibold p-3 text-slate-900 text-left">
                Date
              </th>
              <th style={{minWidth: '220px'}} className="border border-slate-300 bg-slate-50 font-semibold p-3 text-slate-900 text-left">
                Nom
              </th>
              <th className="border border-slate-300 bg-slate-50 font-semibold p-3 text-slate-900 text-center">
                Montant
              </th>
            </tr>
            </thead>
            <tbody>
            {revenues.map((revenue, i) => (
              <RevenueItem
                key={revenue.id}
                fp={fp}
                revenue={revenue}
                onRevenueChange={onRevenueChange}
                onRevenueDelete={onRevenueDelete}
              />
              )
            )}

            <tr className="border-t-2 border-indigo-500">
              <td className="border border-slate-300 font-semibold px-2 py-1 text-slate-900 text-right"></td>
              <td className="border border-slate-300 font-semibold px-2 py-1 text-slate-900 text-right"></td>
              <td className="border border-slate-300 font-semibold px-2 py-1 text-slate-900 text-right"></td>
              <td className="border border-slate-300 font-semibold px-2 py-1 text-slate-900 text-right">
                {revenuesSum}
              </td>
            </tr>
            </tbody>

          </table>
        </div>
      }

      <div className="mt-3">
        <Button
          type="success"
          onClick={onRevenueAdd}>
          + Ajouter
        </Button>
      </div>
    </div>
  )
}
