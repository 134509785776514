
// Example, todo
import randomId from "../utils/randomId";
import round2 from "../utils/round2";

function migrateToV0_2(fp) {
  // Add an ID to revenues
  if (!fp) {
    return fp;
  }

  fp.ranges = fp.ranges || [];

  fp.ranges.forEach((range) => {
    range.revenues = range.revenues || [];
    range.revenues.forEach((revenue) => {
      revenue.id = revenue.id || randomId();
    });
  });

  fp.version = '0.2';
}


function migrateToV0_3(fp) {
  // Format to 5.50 instead of 5.5
  if (!fp) {
    return fp;
  }

  fp.ranges = fp.ranges || [];

  fp.ranges.forEach((range) => {
    range.revenues = range.revenues || [];
    range.revenues.forEach((revenue) => {
      if (revenue.amount) {
        revenue.amount = round2(revenue.amount, true);
      }
    });

    range.itemsInfo.forEach((itemInfo) => {
      if (itemInfo.balanceStart) {
        itemInfo.balanceStart = round2(itemInfo.balanceStart, true);
      }
      if (itemInfo.additions) {
        itemInfo.additions = round2(itemInfo.additions, true);
      }
      if (itemInfo.deductions) {
        itemInfo.deductions = round2(itemInfo.deductions, true);
      }
    });
  });

  fp.bills = fp.bills || [];

  fp.bills.forEach((bill) => {
    if (bill.amount) {
      bill.amount = round2(bill.amount, true);
    }
  });


  fp.version = '0.3';
}


function migrateToV0_4(fp) {
  // Add "archived" boolean to bills
  if (!fp) {
    return fp;
  }
  fp.bills = fp.bills || [];

  fp.bills.forEach((bill) => {
    bill.archived = false;
  });

  fp.version = '0.4';
}


function migrateToV0_5(fp) {
  // "id" to automatically added revenue "Report période précédente" that didn't have any ID
  if (!fp) {
    return fp;
  }
  fp.ranges = fp.ranges || [];

  fp.ranges.forEach((range) => {
    range.revenues.forEach((rev) => {
      if (!rev.id) {
        rev.id = randomId();
      }
    });
  });

  fp.version = '0.5';
}


export default function migrateFP(fp) {
  if (!fp) {
    return fp;
  }
  if (!fp.version || fp.version === '0.1') {
    migrateToV0_2(fp);
  }
  if (fp.version === '0.2') {
    migrateToV0_3(fp);
  }
  if (fp.version === '0.3') {
    migrateToV0_4(fp);
  }
  if (fp.version === '0.4') {
    migrateToV0_5(fp);
  }
  return fp;
}