import {Archive, Trash} from "iconoir-react";
import {useEffect, useState} from "react";

export default function BillItem(
  {
    fp,
    bill,
    onBillDelete,
    onBillChange,
    items,
  }) {

  const [amountLocal, setAmountLocal] = useState(null);

  useEffect(() => {
    setAmountLocal(bill.amount);
  }, [fp, bill]);

  const rowBg = bill.archived ? "bg-gray-100 focus:bg-white" : "";

  return (
    <tr className={rowBg}>
      <th className="border border-slate-300 px-2 text-red-900 text-center">
        <span
          onClick={() => (onBillDelete(bill.id))}
          className="cursor-pointer px-1">
          <Trash width={15}/>
        </span>
      </th>
      <td className="border border-slate-300 font-semibold text-slate-900 text-left px-2">
        <input type="text"
               name="bill-name"
               className={`py-1 px-1 w-full ${rowBg}`}
               placeholder="Nouvelle facture"
               onBlur={() => {
               }}
               onChange={(e) => (onBillChange(bill.id, 'name', e.target.value))}
               value={bill.name}/>
      </td>
      <td className="border border-slate-300 text-slate-900 text-left px-2">
        <input type="text"
               style={{width: '80px'}}
               className={`py-1 text-right ${rowBg}`}
               onBlur={(e) => onBillChange(bill.id, 'amount', amountLocal)}
               onChange={(e) => setAmountLocal(e.target.value)}
               value={amountLocal ?? ''}/>
      </td>
      <td className="border border-slate-300 text-slate-900 text-left px-2">
        <input
          value={bill.dueDate || ''}
          onChange={(e) => onBillChange(bill.id, 'dueDate', e.target.value)}
          type="date"
          className={`border border-gray-300 rounded-md ${rowBg}`}
          name="dueDate" id="dueDate"/>
      </td>
      <td className="border border-slate-300 text-slate-900 text-center px-2">
        <input type="checkbox"
               checked={bill.paid}
               onChange={(e) => (onBillChange(bill.id, 'paid', e.target.checked))}/>
        {bill.paid &&
          <input
            value={bill.paidDate || ''}
            onChange={(e) => onBillChange(bill.id, 'paidDate', e.target.value)}
            type="date"
            className={`border border-gray-300 rounded-md ml-2 ${rowBg}`}
            name="paidDate" id="paidDate"/>
        }
      </td>
      <td className="border border-slate-300 text-slate-900 text-left px-2">
        <select
          value={bill.item || ''}
          style={{width: '200px'}}
          onChange={(e) => onBillChange(bill.id, 'item', e.target.value)}
          className={`border border-gray-300 p-1 rounded-md ${rowBg}`}>
          <option value="">
            -- Aucun --
          </option>
          {
            items.map((item, i) => (
              <option key={i} value={item.id}>
                {item.name}
              </option>
            ))
          }
        </select>
      </td>
      <td className="border border-slate-300 text-slate-900 text-left px-2">
        <input type="text"
               value={bill.comments || ''}
               className={`py-1 ${rowBg} w-full`}
               onChange={(e) => (onBillChange(bill.id, 'comments', e.target.value))}
        />
      </td>
      <td className="border border-slate-300 text-slate-900 text-center px-2 py-1/2">
        <input type="checkbox"
               checked={bill.archived}
               onChange={(e) => (onBillChange(bill.id, 'archived', e.target.checked))}/>
      </td>
    </tr>
  )
}