import {useMemo, useState} from "react";
import Modal from "../../shared/modal";
import getDayName from "../../../utils/getDayName";
import Button from "../../shared/button";

export default function CreatePeriodModal(props) {
  const {periods, cancel, createPeriod, showBringRevenues} = props;

  const lastPeriod = periods[periods.length - 1];
  let defaultStartDate = lastPeriod ? new Date(lastPeriod.dateEnd) : new Date();
  let defaultEndDate = lastPeriod ? new Date(lastPeriod.dateEnd) : new Date();

  // Shift by the timezone
  defaultStartDate.setMinutes(defaultStartDate.getMinutes() - defaultStartDate.getTimezoneOffset());
  defaultEndDate.setMinutes(defaultEndDate.getMinutes() - defaultEndDate.getTimezoneOffset());
  defaultStartDate.setDate(defaultStartDate.getDate() + 1);
  defaultEndDate.setDate(defaultEndDate.getDate() + 7);

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  // Whether the revenues should be added to the next period
  const [bringRevenues, setBringRevenues] = useState(true);


  const daysOfWeek = useMemo(() => {
    return [
      startDate ? getDayName(startDate) : '',
      endDate ? getDayName(endDate) : '',
    ]
  }, [startDate, endDate]);

  const nbDays = useMemo(() => {
    if (!startDate || !endDate) {
      return 0;
    }
    return Math.max(0, Math.floor((new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24)) + 1);
  }, [startDate, endDate]);

  return (
    <Modal
      visible={true}
      close={cancel}
      title={"Créer une nouvelle période"}
      body={<div className="text-left">
        <div className="flex gap-3 text-left items-center">
          <div>
            Du <br/>
            <input
              value={startDate ? new Date(startDate).toISOString().split('T')[0] : ''}
              onChange={(e) => setStartDate(e.target.value)}
              type="date"
              className="border-solid border-2 border-gray-300 rounded-md"
              name="dateStart" id="dateStart" />
            <div className="text-slate-400 text-sm">
              {daysOfWeek[0] || <span>&nbsp;</span>}
            </div>
          </div>

          <div className="">
            Au <br/>
            <input
              value={endDate ? new Date(endDate).toISOString().split('T')[0] : ''}
              onChange={(e) => setEndDate(e.target.value)}
              type="date"
              className="border-solid border-2 border-gray-300 rounded-md"
              name="dateEnd" id="dateEnd" />
            <div className="text-slate-400 text-sm">
              {daysOfWeek[1] || <span>&nbsp;</span>}
            </div>
          </div>

          <div>
            {nbDays} jour{nbDays > 1 ? 's' : ''}
          </div>
        </div>

        {showBringRevenues &&
          <div className="mt-3">
            <label htmlFor="bring-revenues">
              <input
                id="bring-revenues"
                className="mr-2"
                type="checkbox"
                checked={bringRevenues}
                onChange={(e) => (setBringRevenues(e.target.checked))}/>
              Rapporter les revenus
            </label>
            <div className="text-slate-500 text-xs">
              La somme non allouée de la dernière période sera ajoutée <br/> à la nouvelle période.
            </div>
          </div>
        }
      </div>}

      footer={
        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
          <Button
            className="mr-2"
            onClick={cancel}
          >
            Annuler
          </Button>
          <Button
            type="success"
            onClick={() => createPeriod(startDate, endDate, bringRevenues)}
          >
            Confirmer
          </Button>
        </div>}
    >
    </Modal>
  )
}