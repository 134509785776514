
export default function round2(nb, showNeededCents=false) {
  if (nb === null || nb === undefined || nb === "") {
    return "";
  }
  const rounded = Math.round(nb * 100) / 100;
  // Show .50 instead of .5
  if (showNeededCents) {
    const roundedString = rounded.toString();
    const roundedStringSplit = roundedString.split(".");
    if (roundedStringSplit.length === 2 && roundedStringSplit[1].length === 1) {
      return roundedString + "0";
    }
  }
  return rounded;
}
