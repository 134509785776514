// AlertPopup.js
import useAlert from "../../utils/useAlert";

const AlertPopup = () => {
  const { text, color = 'blue' } = useAlert();

  const bgColors = {
    blue: 'bg-blue-100',
    green: 'bg-green-100',
    red: 'bg-red-100',
  };
  const textColors = {
    blue: 'text-blue-700',
    green: 'text-green-700',
    red: 'text-red-700',
  }

  if (text && color) {
    return (
      <div className="fixed bottom-2 w-full px-10" style={{zIndex: 1000}}>
        <div className={`w-full text-center p-4 mb-4 text-sm ${textColors[color]} ${bgColors[color]} rounded-lg shadow-md`}
             role="alert">
          {text}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default AlertPopup;