import getItemBalanceAfter from "../../../utils/getItemBalanceAfter";
import PERIOD_TYPE from "../../../constants/periodType";
import getItemCostsPerPeriod from "../../../utils/getItemCostsPerPeriod";
import {Popover} from "react-tiny-popover";
import {useEffect, useState} from "react";
import {Cancel, Trash} from "iconoir-react";
import Button from "../../shared/button";
import round2 from "../../../utils/round2";

export default function ItemRow(props) {
  const {
    fp,
    fpOnRange,
    item,
    categories,
    currency,
    onFieldChange,
    onItemNameChange,
    onItemCategoryChange,
    onItemCostChange,
    onItemDelete,
    onMoveMouseDown,
    beingMoved,
    isUnderMouse,
    visualMoveBorderTopOrBottom,
    setBillsItemFilter
  } = props;

  const [deletePopoverOpen, setDeletePopoverOpen] = useState(false);
  const itemInfo = fpOnRange.itemsInfo.find((info) => info.item === item.id) || {};
  const balanceAfter = getItemBalanceAfter(itemInfo);

  const [itemInfoLocal, setItemInfoLocal] = useState({
    ...item,
  });

  const itemCostPerPeriod = getItemCostsPerPeriod(item);
  const [itemCostPerPeriodLocal, setItemCostPerPeriodLocal] = useState(getItemCostsPerPeriod(item));

  const isTopBorder = visualMoveBorderTopOrBottom === 'top';
  const borderWidth = isUnderMouse ? (isTopBorder ? '9px 0 0 0' : '0 0 9px 0') : '0px';
  const borderColor = isUnderMouse ? 'rgba(50, 200, 20, 0.5)' : 'transparent';

  useEffect(() => {
    setItemCostPerPeriodLocal(getItemCostsPerPeriod(item));
    setItemInfoLocal({
      ...itemInfo,
    });
  }, [fp, item, fpOnRange]);

  function onFieldLocalChange(field, newValue) {
    setItemInfoLocal({
      ...itemInfo,
      [field]: newValue,
    });
  }

  function onItemCostLocalChange(periodType, newCost) {
    setItemCostPerPeriodLocal({
      ...itemCostPerPeriod,
      [periodType]: newCost,
    });
  }

  function onAskRemoveClick(e) {
    e.stopPropagation();
    setDeletePopoverOpen(true);
  }

  function onRemoveCancel(e) {
    e.stopPropagation();
    setDeletePopoverOpen(false);
  }

  return (
    <tr key={item.id}
        data-item-id={item.id}
        style={{borderColor, borderWidth}}
        className={beingMoved ? 'opacity-30' : ''}>
      <th
        onClick={onAskRemoveClick}
        className="cursor-pointer border border-slate-300 px-2 text-red-900 text-center">

        <Popover
          isOpen={deletePopoverOpen}
          positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
          onClickOutside={() => setDeletePopoverOpen(false)}
          content={
            <div className="bg-white border-red-300 ml-2 border shadow-md px-4 py-4">
              <div>
                <div className="text-center text-3xl text-red-500">
                  &#9888;
                </div>
                Confirmer la suppression de l'élément ?
                <br/>
                Il sera supprimé de toutes les périodes.

              </div>
              <div
                className="mt-5 text-center">
                <Button
                  onClick={onRemoveCancel}>
                  Annuler
                </Button>
                <Button
                  type="danger"
                  className="ml-2"
                  onClick={() => onItemDelete(item.id)}>
                  Supprimer
                </Button>
              </div>
            </div>
          }
        >
          <Trash width={15}/>
        </Popover>

      </th>
      <th
        onMouseDown={onMoveMouseDown}
        className="cursor-move border border-slate-300 px-5 text-center">
        &#8597;
      </th>
      <th className="border border-slate-300 font-semibold px-3 text-slate-900 text-left">
        <input type="text"
               className="py-1 w-full"
               placeholder="Nouvelle ligne"
               onBlur={() => {}}
               onChange={(e) => (onItemNameChange(item.id, e.target.value))}
               value={item.name}/>
      </th>

      <td className="border border-slate-300 font-semibold text-slate-900 text-left">
        <select
          className="w-full py-1"
          value={item.category || ''}
          onChange={(e) => onItemCategoryChange(item.id, e.target.value)}
        >
          <option value=''></option>
          {categories.map((category) => (
            <option
              key={category.id}
              value={category.id}>
              {category.name || '-'}
            </option>
          ))}
        </select>
      </td>

      <td className={'text-right border border-slate-300 text-slate-500'}>
        <input type="text"
               className="text-right number-input px-1"
               onChange={(e) => onItemCostLocalChange(PERIOD_TYPE.WEEK, e.target.value)}
               onBlur={(e) => onItemCostChange(item.id, PERIOD_TYPE.WEEK, itemCostPerPeriodLocal[PERIOD_TYPE.WEEK])}
               value={itemCostPerPeriodLocal[PERIOD_TYPE.WEEK]}/>
      </td>
      <td className={'text-right border border-slate-300 text-slate-500'}>
        <input type="text"
               className="text-right number-input px-1"
               onChange={(e) => onItemCostLocalChange(PERIOD_TYPE.MONTH, e.target.value)}
               onBlur={(e) => onItemCostChange(item.id, PERIOD_TYPE.MONTH, itemCostPerPeriodLocal[PERIOD_TYPE.MONTH])}
               value={itemCostPerPeriodLocal[PERIOD_TYPE.MONTH]}/>
      </td>

      <td className={'text-right border border-slate-300 text-slate-500'}>
        <input type="text"
               className="text-right number-input px-1"
               onChange={(e) => onItemCostLocalChange(PERIOD_TYPE.YEAR, e.target.value)}
               onBlur={(e) => onItemCostChange(item.id, PERIOD_TYPE.YEAR, itemCostPerPeriodLocal[PERIOD_TYPE.YEAR])}
               value={itemCostPerPeriodLocal[PERIOD_TYPE.YEAR]}/>
      </td>

      <td className={(itemInfo.balanceStart < 0 ? 'text-red-400' : '') + ' text-right border border-slate-300 text-slate-500'}>
        <input type="text"
               className="text-right number-input px-2"
               onChange={(e) => onFieldLocalChange('balanceStart', e.target.value)}
               onBlur={(e) => onFieldChange('balanceStart', itemInfoLocal.balanceStart)}
               value={itemInfoLocal.balanceStart ?? ''}/>
      </td>

      <td className={(itemInfo.additions < 0 ? 'text-red-400' : '') + ' border border-slate-300 text-slate-500'}>
        <input type="text"
               className="text-right number-input px-2 text-green-700"
               onChange={(e) => onFieldLocalChange('additions', e.target.value)}
               onBlur={(e) => onFieldChange('additions', itemInfoLocal.additions)}
               value={itemInfoLocal.additions ?? ''}/>
      </td>

      <td className={(itemInfo.deductions > 0 ? 'text-red-400' : '') + ' border border-slate-300 text-slate-500'}>
        <input type="text"
               className="text-right number-input px-2"
               onChange={(e) => onFieldLocalChange('deductions', e.target.value)}
               onBlur={(e) => onFieldChange('deductions', itemInfoLocal.deductions)}
               value={itemInfoLocal.deductions ?? ''}/>
      </td>

      <td className={(balanceAfter < 0 ? 'text-red-400' : '') + ' px-2 text-right border border-slate-300 text-slate-500'}>
        {round2(balanceAfter || 0, true)}
      </td>

      <td className={'px-2 text-center border border-slate-300 text-slate-500'}>
        <img
          width={20}
          onClick={() => (setBillsItemFilter(item.id))}
          className="cursor-pointer m-auto"
          src="/images/eye.png" alt="Voir"/>
      </td>
    </tr>
  )
}