import {useState} from "react";
import Button from "../../shared/button";

export default function DeletePeriod(props) {
  const {
    onDelete,
  } = props;

  const [showConfirm, setShowConfirm] = useState(false);

  const onClick = () => {
    if (!showConfirm) {
      setShowConfirm(true);
    } else {
      onDelete();
    }
  }

  return (
    <div>
      <div>
        Supprimer cette période
        <Button
          onClick={onClick}
          type="danger"
          className="ml-3 mr-3"
        >
          &#9888; {showConfirm ? <span>Confirmer la suppression définitive</span> : <span>Supprimer</span>}
        </Button>

        {showConfirm &&
            <Button
              onClick={() => setShowConfirm(false)}
            >Annuler</Button>
          }
      </div>
    </div>
  )
}
