import { useEffect, useState } from "react";

let prev = null;
let isMouseDown = false;

export const useMouseTarget = () => {
  const [target, setTarget] = useState({ target: null });

  useEffect(() => {
    const setFromEvent = (e) => {
      if (isMouseDown && e.target !== prev) {
        setTarget({ target: e.target });
        prev = e.target;
      }
    };

    const onMouseDownEvent = (e) => {
      isMouseDown = true;
    }
    const onMouseUpEvent = (e) => {
      isMouseDown = false;
    }
    window.addEventListener("mousemove", setFromEvent);
    window.addEventListener("mousedown", onMouseDownEvent);
    window.addEventListener("mouseup", onMouseUpEvent);

    return () => {
      window.removeEventListener("mousemove", setFromEvent);
      window.removeEventListener("mousedown", onMouseDownEvent);
      window.removeEventListener("mouseup", onMouseUpEvent);
    };
  }, []);

  return target;
};