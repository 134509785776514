import {useEffect, useMemo, useState} from "react";
import ItemRow from "./itemRow";
import {db} from "../../../localDB";
import getItemBalanceAfter from "../../../utils/getItemBalanceAfter";
import RevenuesTable from "./revenuesTable";
import {useMouseTarget} from "../../../utils/useMouseTarget";
import migrateFP from "../../../localDB/migrateFP";
import BORDER_MOVE_POSITION from "../../../constants/borderMovePosition";
import DeletePeriod from "./deletePeriod";
import formatDate from "../../../utils/formatDate";
import exampleDataEmpty from "../../../localDB/exampleDataEmpty";
import NoFP from "./noFP";
import useAlert from "../../../utils/useAlert";
import CreatePeriodModal from "./createPeriodModal";
import getItemCostsPerPeriod from "../../../utils/getItemCostsPerPeriod";
import PeriodType from "../../../constants/periodType";
import round2 from "../../../utils/round2";
import CategoriesTable from "./categoriesTable";
import SectionTitle from "../../shared/sectionTitle";
import Bills from "./bills";
import Draggable from "react-draggable";
import {ArrowUp, Cancel, EditPencil, OpenInWindow} from "iconoir-react";
import {EditPeriodModal} from "./editPeriod";
import AnalysisReports from "./analysisReports";
import Button from "../../shared/button";
import getValidNb from "../../../utils/getValidNb";
import randomId from "../../../utils/randomId";
import periodDatesOK from "../../../utils/periodDatesOK";

const TABS = {
  PLAN: 'plan',
  REVENUES: 'revenues',
  CATEGORIES: 'categories',
  DELETE_PERIOD: 'delete-period',
  BILLS: 'bills',
  ANALYSIS: 'analysis',
}

export default function FinancialPlan() {

  const [fp, setFp] = useState(null);
  const [loading, setLoading] = useState(true);
  const [periodIndex, setPeriodIndex] = useState(0);
  const [createPeriodVis, setCreatePeriodVis] = useState(false);
  const [currentTab, setCurrentTab] = useState(TABS.PLAN);
  const [showBillsWindow, setShowBillsWindow] = useState(false);
  const [itemBeingMoved, setItemBeingMoved] = useState(null);
  const [billsItemFilter, setBillsItemFilter] = useState(null); // Filter bills on Item ID
  const [showPeriodEdit, setShowPeriodEdit] = useState(false);

  const targetInfo = useMouseTarget();
  const {setAlert} = useAlert();

  const itemIdUnderMouse = useMemo(() => {
    if (!itemBeingMoved || !targetInfo || !targetInfo.target) {
      return null;
    }
    const target = targetInfo.target;
    let parentRow = null;
    if (target.tagName.toLowerCase() === 'input' && target.parentElement && target.parentElement.parentElement &&
      target.parentElement.parentElement.tagName.toLowerCase() === 'tr') {
      parentRow = target.parentElement.parentElement;
    } else if (['th', 'td'].includes(target.tagName.toLowerCase())) {
      parentRow = target.parentElement;
    } else if (target.tagName.toLowerCase() === 'tr') {
      parentRow = target;
    }

    if (parentRow) {
      const itemId = parentRow.getAttribute('data-item-id') || 0;
      return parseInt(itemId);
    }
    return null;
  }, [targetInfo, itemBeingMoved]);


  const visualMoveBorderTopOrBottom = useMemo(() => {
    if (!fp || !fp.items || !itemBeingMoved || !itemIdUnderMouse) {
      return BORDER_MOVE_POSITION.TOP
    }
    const indexUnderMouse = fp.items.findIndex(item => item.id === itemIdUnderMouse);
    const indexBeingMoved = fp.items.findIndex(item => item.id === itemBeingMoved.id);
    return indexUnderMouse >= indexBeingMoved ? BORDER_MOVE_POSITION.BOTTOM : BORDER_MOVE_POSITION.TOP;
  }, [fp, itemIdUnderMouse, itemBeingMoved]);

  const balanceStartSum = useMemo(() => {
    return getSumsOfField('balanceStart');
  }, [getSumsOfField]);

  const additionsSum = useMemo(() => {
    return getSumsOfField('additions');
  }, [getSumsOfField]);

  const deductionsSum = useMemo(() => {
    return getSumsOfField('deductions');
  }, [getSumsOfField]);

  const balanceAfterSum = useMemo(() => {
    return balanceStartSum + additionsSum - deductionsSum;
  }, [balanceStartSum, additionsSum, deductionsSum]);

  const sumPerPeriodType = useMemo(() => {
    if (!fp || !fp.items) return 0;

    const results = fp.items.reduce((acc, item) => {
      if (!item) {
        return acc;
      }
      const itemCostPerPeriod = getItemCostsPerPeriod(item);
      acc[PeriodType.WEEK] = (acc[PeriodType.WEEK] || 0) + parseFloat(itemCostPerPeriod[PeriodType.WEEK] || 0);
      acc[PeriodType.MONTH] = (acc[PeriodType.MONTH] || 0) + parseFloat(itemCostPerPeriod[PeriodType.MONTH] || 0);
      acc[PeriodType.YEAR] = (acc[PeriodType.YEAR] || 0) + parseFloat(itemCostPerPeriod[PeriodType.YEAR], 0);
      return acc;
    }, {[PeriodType.WEEK]: 0, [PeriodType.MONTH]: 0, [PeriodType.YEAR]: 0});

    results[PeriodType.WEEK] = round2(results[PeriodType.WEEK] || 0, true);
    results[PeriodType.MONTH] = round2(results[PeriodType.MONTH] || 0, true);
    results[PeriodType.YEAR] = round2(results[PeriodType.YEAR] || 0, true);

    return results;
  }, [fp]);

  const sortedCategories = useMemo(() => {
    return (fp && fp.categories ? fp.categories : []).sort((a, b) => (a.name || '').localeCompare(b.name || ''));
  }, [fp]);


  function getSumsOfField(field) {
    if (!fp) return 0;
    const fpOnRange = fp?.ranges[periodIndex];
    if (!fpOnRange || !fpOnRange.itemsInfo) return 0;

    return fpOnRange.itemsInfo.reduce((acc, itemInfo) => {
      return acc + parseFloat(itemInfo[field] || 0);
    }, 0);
  }

  useEffect(() => {
    loadFp();
    const hash = window.location.hash;
    if (hash) {
      setBillsItemFilter(null);
      setCurrentTab(hash.slice(1));
    }
  }, []);

  useEffect(() => {
    const onMouseUp = () => {
      if (!fp || !itemBeingMoved || !itemIdUnderMouse || itemBeingMoved.id === itemIdUnderMouse) {
        setItemBeingMoved(null);
        return;
      }
      const itemUnderMouse = fp.items.find(item => item.id === itemIdUnderMouse);
      if (itemUnderMouse) {
        // Insert itemBeingMoved before itemUnderMouse
        const newItems = [...fp.items];
        const itemBeingMovedIndex = newItems.findIndex(item => item.id === itemBeingMoved.id);
        const itemUnderMouseIndex = newItems.findIndex(item => item.id === itemUnderMouse.id);

        if (itemBeingMovedIndex > -1 && itemUnderMouseIndex > -1) {
          newItems.splice(itemUnderMouseIndex, 0, newItems.splice(itemBeingMovedIndex, 1)[0]);
          const newFp = {...fp, items: newItems};
          setFp(newFp);
        }
      }
      setItemBeingMoved(null);
    };
    const onMouseClick = () => {
      setItemBeingMoved(null);
    };

    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('click', onMouseClick);
    return () => {
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('click', onMouseClick);
    }
  }, [fp, itemBeingMoved, itemIdUnderMouse]);

  useEffect(() => {
    if (fp) {
      db.storeFPToLocalstorage(fp);
    }
  }, [fp]);

  const loadFp = async () => {
    try {
      setLoading(true);
      const fp = await db.getFPFromLocalStorage();
      if (fp) {
        migrateFP(fp);
        if (fp.periods && fp.periods.length > 0) {
          setPeriodIndex(fp.periods.length - 1);
        }
        if (!fp.periods || fp.periods.length === 0) {
          setCreatePeriodVis(true);
        }
      }
      setFp(fp);

    } catch (error) {

    } finally {
      setLoading(false);
    }
  }

  const loadEmptyFP = () => {
    setFp(migrateFP(exampleDataEmpty));
    setCreatePeriodVis(true);
  }

  if (loading) {
    return <div></div>;
  } else if (!fp) {
    return <NoFP loadEmptyFP={loadEmptyFP}/>
  }

  const currency = fp.financialPlan.currency;
  const period = fp.periods ? fp.periods[periodIndex] : null;
  const fpOnRange = fp.ranges ? fp.ranges.find((range) => range.period === period.id) : null;

  function onFieldChange(itemId, field, value) {
    if (!fp) {
      return;
    }
    let itemInfo = fpOnRange.itemsInfo.find((info) => info.item === itemId);

    if (!itemInfo) {
      itemInfo = {
        item: itemId,
        balanceStart: 0,
        additions: 0,
        deductions: 0,
      };
      fpOnRange.itemsInfo.push(itemInfo);
    }

    let newVal = getValidNb(value);
    if (newVal === null) {
      newVal = "";
    }

    itemInfo[field] = round2(newVal, true);

    setFp({...fp});
  }

  function addRow() {
    if (!fp) {
      return;
    }
    const newId = (fp.items && fp.items.length > 0) ? (Math.max(...fp.items.map((item) => item.id)) + 1) : 1;
    const newItem = {
      id: newId,
      name: '',
      cost: 0,
      periodType: "MONTH",
    };
    fp.items.push(newItem);
    setFp({...fp});
  }

  function onItemNameChange(itemId, newName) {
    if (!fp) {
      return;
    }
    const item = fp.items.find((item) => item.id === itemId);
    if (item) {
      item.name = newName;
      setFp({...fp});
    }
  }

  function onItemCategoryChange(itemId, newCategory) {
    if (!fp) {
      return;
    }
    const item = fp.items.find((item) => item.id === itemId);
    if (item) {
      item.category = newCategory;
      setFp({...fp});
    }
  }

  function onItemCostChange(itemId, periodType, newCost) {
    if (!fp) {
      return;
    }
    let cost = getValidNb(newCost);
    if (cost === null) {
      cost = '';
    }
    const item = fp.items.find((item) => item.id === itemId);
    if (item) {
      item.cost = cost;
      item.periodType = periodType;
      setFp({...fp});
    }
  }

  function onItemDelete(itemId) {
    if (!fp) {
      return;
    }
    itemId = Number(itemId);
    fp.items = fp.items.filter((item) => (Number(item.id) !== itemId));
    fp.ranges.forEach((range) => {
      range.itemsInfo = range.itemsInfo.filter((itemInfo) => (Number(itemInfo.item) !== itemId));
    });
    fp.bills.forEach((bill) => {
      if (Number(bill.item) === itemId) {
        bill.item = "";
      }
    });
    setFp({...fp});
  }


  function onMoveMouseDown(e, item) {
    e.preventDefault();
    setItemBeingMoved(item);
  }

  function onCreatePeriod(startDate, endDate, bringRevenues) {
    if (!fp) {
      return;
    }
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    if (!periodDatesOK(startDate, endDate)) {
      setAlert("Les dates ne sont pas correctes", "red");
      return;
    }

    fp.periods = fp.periods || [];
    fp.ranges = fp.ranges || [];
    const isFirstPeriod = fp.periods.length === 0;
    const newPeriodId = isFirstPeriod ? 0 : Math.max(...fp.periods.map((period) => period.id)) + 1;
    const newRangeId = isFirstPeriod ? 0 : Math.max(...fp.ranges.map((range) => range.id)) + 1;

    const dateStart = new Date(startDate).toISOString().split('T')[0];
    const newPeriod = {
      "id": newPeriodId,
      "name": "Nouvelle période",
      "dateStart": dateStart,
      "dateEnd": new Date(endDate).toISOString().split('T')[0],
    };
    fp.periods.push(newPeriod);
    const previousFPRange = fp.ranges[fp.ranges.length - 1];
    let prevItemsInfo = previousFPRange ? previousFPRange.itemsInfo : [];

    const newItemsInfo = prevItemsInfo.map((itemInfo) => {
      const balanceAfter = getItemBalanceAfter(itemInfo);
      return {
        item: itemInfo.item,
        balanceStart: balanceAfter || 0,
        additions: 0,
        deductions: 0,
      };
    });
    const revenues = [];
    if (bringRevenues && sumToAllocate) {
      revenues.push({
        id: randomId(),
        date: dateStart,
        name: "Report période précédente",
        amount: round2(sumToAllocate || 0, true),
      });
    }
    fp.ranges.push({
      id: newRangeId,
      period: newPeriodId,
      revenues,
      itemsInfo: newItemsInfo,
    });
    setFp({...fp});
    setPeriodIndex(fp.periods.length - 1);
    setCreatePeriodVis(false);

    setAlert("La nouvelle période a été créée, bonne planification !", "green");
  }


  // CATEGORY CHANGES

  function onCategoryDelete(id) {
    if (!fp) {
      return;
    }
    const category = (fp.categories).findIndex((category) => category.id === id);
    fp.categories = (fp.categories || []).filter((category) => category.id !== id);
    if (category) {
      fp.items.forEach((item) => {
        if (parseInt(item.category) === parseInt(category.id)) {
          item.category = null;
        }
      });
    }
    setFp({...fp});
  }

  function onCategoryAdd() {
    if (!fp) {
      return;
    }
    fp.categories = fp.categories || [];
    const newId = (fp.categories && fp.categories.length > 0) ? (Math.max(...fp.categories.map((category) => category.id)) + 1) : 1;
    const newCategory = {
      id: newId,
      name: "",
    };
    fp.categories.push(newCategory);
    setFp({...fp});
  }

  function onCategoryChange(id, partialCategory) {
    if (!fp) {
      return;
    }
    const category = (fp.categories || []).find((cat) => (cat.id === id));
    if (!category) {
      return;
    }
    Object.assign(category, partialCategory);
    setFp({...fp});
  }

  function onPeriodDelete() {
    if (!fp || !period) {
      return;
    }
    fp.periods.splice(periodIndex, 1);
    const rangeIndex = fp.ranges.findIndex((range) => range.period === period.id);
    if (rangeIndex >= 0) {
      fp.ranges.splice(rangeIndex, 1);
    }
    if (fp.periods.length === 0) {
      setFp(null);
      db.storeFPToLocalstorage(null);
    } else {
      setPeriodIndex(fp.periods.length - 1);
      setFp({...fp});
    }
    setCurrentTab(TABS.PLAN);
  }

  function scrollToTop() {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }

  const sumOfNegativeRevenues = fpOnRange ? fpOnRange.revenues.reduce((acc, revenue) => {
    return acc + Math.min(0, (parseFloat(revenue.amount || 0) || 0));
  }, 0) : 0;

  const sumOfPositiveRevenues = fpOnRange ? fpOnRange.revenues.reduce((acc, revenue) => {
    return acc + Math.max(0, (parseFloat(revenue.amount || 0) || 0));
  }, 0) : 0;

  const sumOfRevenues = sumOfNegativeRevenues + sumOfPositiveRevenues;
  const sumToAllocate = (sumOfRevenues || 0) - (additionsSum || 0);

  return (
    <div>

      {(showPeriodEdit && period) && (
        <EditPeriodModal
          period={period}
          cancel={() => (setShowPeriodEdit(false))}
          onChange={({dateStart, dateEnd}) => {
            period.dateStart = dateStart;
            period.dateEnd = dateEnd;
            setFp({...fp});
            setShowPeriodEdit(false);
          }
          }
        />
      )}

      {showBillsWindow &&
        <Draggable>
          <div className="fixed w-fit bg-white px-3 py-2 border-2 cursor-move shadow-md z-10">
            <SectionTitle>Factures</SectionTitle>

            <span
              onClick={() => setShowBillsWindow(false)}
              className="px-1 text-lg absolute text-red-900 cursor-pointer font-bold top-0 right-0">
              <Cancel/>
            </span>

            {fp &&
              <Bills
                fp={fp}
                setFp={setFp}
                period={period}
                billsItemFilter={billsItemFilter}
                setBillsItemFilter={setBillsItemFilter}
                scrollable={true}
              />
            }
          </div>
        </Draggable>
      }

      <div className="flex flex-row mb-6">
        <div className="basis-1/2">
          {period &&
            <div>
              {formatDate(period.dateStart)} &#8594; {formatDate(period.dateEnd)}
              <EditPencil
                onClick={() => (setShowPeriodEdit(true))}
                width={20} className="ml-2 cursor-pointer"/>
            </div>
          }

          {(fp && fp.periods && fp.periods.length > 1) && (
            <div className="mt-4">
              <Button
                type="standard"
                className="mr-4"
                disabled={periodIndex === 0}
                onClick={() => (setPeriodIndex(periodIndex-1))}>
                Période précédente
              </Button>

              <Button
                type="standard"
                disabled={periodIndex === fp.periods.length - 1}
                onClick={() => (setPeriodIndex(periodIndex+1))}>
                Période suivante
              </Button>
            </div>
          )}
        </div>
        <div className="basis-1/2 text-right">
          {period &&
            <Button
              type="success"
              onClick={() => setCreatePeriodVis(true)}>
              Passer à la prochaine période
            </Button>
          }

          <div className="mt-4">
            {
              createPeriodVis &&
              <CreatePeriodModal
                showBringRevenues={fp && fp.periods && fp.periods.length > 0}
                createPeriod={onCreatePeriod}
                cancel={() => (setCreatePeriodVis(false))}
                periods={fp ? fp.periods : []}/>}
          </div>
        </div>
      </div>

      {fp && fp.periods && fp.periods.length > 0 && fp.ranges && fp.ranges.length > 0 && (
        <div className="">
          <ul
            className="mb-4 flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200">
            <li className="mr-2">
              <a href={'#' + TABS.PLAN} aria-current="page"
                 onClick={() => setCurrentTab(TABS.PLAN)}
                 className={(currentTab === TABS.PLAN ? 'text-blue-600 bg-gray-100' : '') + ' inline-block p-4 rounded-t-lg active'}>
                Plan financier
              </a>
            </li>
            <li className="mr-2">
              <a href={'#' + TABS.REVENUES}
                 onClick={() => setCurrentTab(TABS.REVENUES)}
                 className={(currentTab === TABS.REVENUES ? 'text-blue-600 bg-gray-100' : '') + ' inline-block p-4 rounded-t-lg'}>
                Revenus
              </a>
            </li>
            <li className="mr-2">
              <a href={'#' + TABS.BILLS}
                 onClick={() => {
                   setShowBillsWindow(false);
                   setBillsItemFilter(null);
                   setCurrentTab(TABS.BILLS);
                 }}
                 className={(currentTab === TABS.BILLS ? 'text-blue-600 bg-gray-100' : '') + ' inline-block p-4 rounded-t-lg'}>
                Factures
              </a>
            </li>
            <li className="mr-2">
              <a href={'#' + TABS.CATEGORIES}
                 onClick={() => setCurrentTab(TABS.CATEGORIES)}
                 className={(currentTab === TABS.CATEGORIES ? 'text-blue-600 bg-gray-100' : '') + ' inline-block p-4 rounded-t-lg'}>
                Catégories
              </a>
            </li>
            <li className="mr-2">
              <a href={'#' + TABS.ANALYSIS}
                 onClick={() => setCurrentTab(TABS.ANALYSIS)}
                 className={(currentTab === TABS.ANALYSIS ? 'text-blue-600 bg-gray-100' : '') + ' inline-block p-4 rounded-t-lg'}>
                Analyses
              </a>
            </li>
            {fp && fp.periods && fp.periods.length > 1 && (
              <li className="mr-2">
                <a href={'#' + TABS.DELETE_PERIOD}
                   onClick={() => setCurrentTab(TABS.DELETE_PERIOD)}
                   className={(currentTab === TABS.DELETE_PERIOD ? 'text-blue-600 bg-gray-100' : '') + ' inline-block p-4 rounded-t-lg'}>
                  Suppression
                </a>
              </li>
            )
            }

          </ul>

          {currentTab === TABS.PLAN &&
            <div className="w-full">

              <SectionTitle>Plan financier</SectionTitle>
              {(fp && fp.items && fp.items.length > 0) && (
                <div className="mb-2">
                  <table>
                    <tbody>
                    <tr>
                      <td>Revenus:</td>
                      <td className="px-3">
                        {round2(sumOfRevenues || 0, true)}
                        {sumOfNegativeRevenues < 0 && (
                          <span className="ml-1 text-gray-500">
                          ({round2(sumOfPositiveRevenues || 0, true)} - {round2(-sumOfNegativeRevenues || 0, true)})
                          </span>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td>Alloué:</td>
                      <td className={'px-3 text-green-700'}>{round2(additionsSum || 0, true)}</td>
                    </tr>
                    <tr>
                      <td>À allouer:</td>
                      <td className={'px-3 ' + (sumToAllocate < 0 ? 'text-red-500' : '')}>{round2(sumToAllocate || 0, true)}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              )}

              <div>
                {(fp && fp.items && fp.items.length > 0) ? (
                  <div>
                    <table className="border-collapse border border-slate-400 bg-white text-sm shadow-sm">
                      <thead className="top-0 sticky">
                      <tr>
                        <th className="border border-slate-300 bg-slate-50 font-semibold p-3 text-slate-900 text-left">
                        </th>
                        <th className="border border-slate-300 bg-slate-50 font-semibold p-3 text-slate-900 text-left">
                        </th>
                        <th
                          className="w-96 border border-slate-300 bg-slate-50 font-semibold p-3 text-slate-900 text-left">
                          Détails
                        </th>
                        <th
                          className="border border-slate-300 bg-slate-50 font-semibold p-3 text-slate-900 text-left">
                          Catégorie
                        </th>
                        <th className="w-10 text-center border border-slate-300 bg-slate-50 font-semibold p-3 text-slate-900 text-left">
                          Par
                          semaine
                        </th>
                        <th className="text-center border border-slate-300 bg-slate-50 font-semibold p-3 text-slate-900 text-left">
                          Par mois
                        </th>
                        <th className="text-center border border-slate-300 bg-slate-50 font-semibold p-3 text-slate-900 text-left">
                          Par an
                        </th>
                        <th className="text-center border border-slate-300 bg-slate-50 font-semibold p-3 text-slate-900 text-left">
                          Balance <br/>
                          début
                        </th>
                        <th className="text-center border border-slate-300 bg-slate-50 font-semibold p-3 text-slate-900 text-left">
                          Alloué
                        </th>
                        <th className="text-center border border-slate-300 bg-slate-50 font-semibold p-3 text-slate-900 text-left">
                          Payé
                        </th>
                        <th className="text-center border border-slate-300 bg-slate-50 font-semibold p-3 text-slate-900 text-left">
                          Balance <br/>
                          fin
                        </th>
                        <th className="text-center border border-slate-300 bg-slate-50 font-semibold p-3 text-slate-900 text-left">
                          Factures
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      {fp.items.map((item) => (
                        <ItemRow
                          key={item.id}
                          item={item}
                          fp={fp}
                          categories={sortedCategories}
                          fpOnRange={fpOnRange}
                          currency={currency}
                          onFieldChange={(field, newVal) => onFieldChange(item.id, field, newVal)}
                          onItemNameChange={onItemNameChange}
                          onItemCategoryChange={onItemCategoryChange}
                          onItemCostChange={onItemCostChange}
                          onItemDelete={onItemDelete}
                          onMoveMouseDown={(e) => onMoveMouseDown(e, item)}
                          beingMoved={itemBeingMoved === item}
                          isUnderMouse={itemIdUnderMouse === item.id}
                          visualMoveBorderTopOrBottom={visualMoveBorderTopOrBottom}
                          setBillsItemFilter={(itemId) => {
                            setBillsItemFilter(itemId);
                            setShowBillsWindow(true);
                            //setCurrentTab(TABS.BILLS);
                          }}
                        />
                      ))}
                      <tr className="border-t-2 border-indigo-500">
                        <th className="border border-slate-300 font-semibold px-2 py-1 text-slate-900 text-left"></th>
                        <th className="border border-slate-300 font-semibold px-2 py-1 text-slate-900 text-left"></th>
                        <th className="border border-slate-300 font-semibold px-2 py-1 text-slate-900 text-left"></th>
                        <th className="border border-slate-300 font-semibold px-2 py-1 text-slate-900 text-left"></th>
                        <th className="border border-slate-300 font-semibold px-2 py-1 text-slate-900 text-right">
                          {(sumPerPeriodType[PeriodType.WEEK])}
                        </th>
                        <th className="border border-slate-300 font-semibold px-2 py-1 text-slate-900 text-right">
                          {(sumPerPeriodType[PeriodType.MONTH])}
                        </th>
                        <th className="border border-slate-300 font-semibold px-2 py-1 text-slate-900 text-right">
                          {(sumPerPeriodType[PeriodType.YEAR])}
                        </th>
                        <th className="border text-right border-slate-300 font-semibold px-2 py-1 text-slate-900 text-left">
                          {round2(balanceStartSum || 0, true)}
                        </th>
                        <th className="border text-right border-slate-300 font-semibold px-2 py-1 text-slate-900 text-left">
                          {round2(additionsSum || 0, true, true)}
                        </th>
                        <th className="border text-right border-slate-300 font-semibold px-2 py-1 text-slate-900 text-left">
                          {round2(deductionsSum || 0, true)}
                        </th>
                        <th className="border text-right border-slate-300 font-semibold px-2 py-1 text-slate-900 text-left">
                          {round2(balanceAfterSum || 0, true)}
                        </th>
                        <th className="border text-right border-slate-300 font-semibold px-2 py-1 text-slate-900 text-left">
                        </th>
                      </tr>

                      </tbody>
                    </table>

                    <span
                      onClick={scrollToTop}
                      className="fixed bottom-2 left-1 text-xl cursor-pointer hover:text-slate-600">
                      <ArrowUp/>
                    </span>
                  </div>
                ) : <div>
                  <p className="text-gray-500">Aucun élément</p>
                </div>
                }

                <div className="mt-3">
                  <Button
                    type="success"
                    onClick={addRow}>
                    + Ajouter
                  </Button>
                </div>
              </div>
            </div>
          }
          {currentTab === TABS.REVENUES &&
            <div className="w-full">
              {fpOnRange &&
                <RevenuesTable
                  revenues={fpOnRange.revenues}
                  fp={fp}
                  fpOnRange={fpOnRange}
                  setFp={setFp}
                />
              }
            </div>
          }

          {currentTab === TABS.CATEGORIES &&
            <div className="w-full">
              {fp &&
                <CategoriesTable
                  fp={fp}
                  onCategoryAdd={onCategoryAdd}
                  onCategoryChange={onCategoryChange}
                  onCategoryDelete={onCategoryDelete}
                />
              }
            </div>
          }

          {currentTab === TABS.ANALYSIS &&
            <div className="w-full">
              {fp &&
                <AnalysisReports
                  fp={fp}
                />
              }
            </div>
          }

          {currentTab === TABS.DELETE_PERIOD &&
            <div className="w-full py-4">

              <DeletePeriod
                onDelete={onPeriodDelete}
              />
            </div>
          }

          {currentTab === TABS.BILLS &&
            <div className="w-full">
              <SectionTitle>
                <span>Factures <OpenInWindow onClick={() => (setShowBillsWindow(true))} className="cursor-pointer" width={20}/></span>
              </SectionTitle>

              {fp &&
                <Bills
                  fp={fp}
                  setFp={setFp}
                  period={period}
                  billsItemFilter={billsItemFilter}
                  setBillsItemFilter={setBillsItemFilter}
                />
              }
            </div>
          }
        </div>
      )
      }
    </div>
  )
}
