import SectionTitle from "../../shared/sectionTitle";
import {VictoryChart, VictoryBar, VictoryLabel, VictoryLine, VictoryStack, VictoryAxis, VictoryTheme, VictoryPie} from 'victory';
import {useMemo, useState} from "react";

const start = new Date();
start.setMonth(start.getMonth() - 3);
// set it to first day of month
start.setDate(1);

const end = new Date();
end.setMonth(end.getMonth() + 1);
// set date to last day of month
end.setDate(0);

const getIso = (date) => date.toISOString().split('T')[0];

function getCategoryName(catId, fp) {
  const cat = (fp.categories || []).find((cat) => cat.id == catId);
  return cat ? cat.name : 'Aucune catégorie';
}

function getColorForCategory(catId) {
  // Generate 30 unique colors
  const colors = [
    '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
    '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
  ];
  return colors[catId % colors.length];

}


export default function AnalysisReport({fp}) {
  const [hoverInfo, setHoverInfo] = useState(null); // {depense, period, category}
  const [filters, setFilters] = useState({
    start: getIso(start),
    end: getIso(end),
    category: '',
  });

  window.fp = fp;

  const {
    perCategoryForAllPeriods,
    periodsInFilter,
    rangesInFilter,
    totalPerPeriod,
    totalPerCategory,
    resultsPerPeriod,
  } = useMemo(() => {
    // Map item ID to category
    const itemIdToCategory = {};
    (fp.items || []).forEach((item) => {
      if (!item.category) {
        itemIdToCategory[item.id] = null;
        return;
      }
      const category = (fp.categories || []).find((cat) => cat.id == item.category); // Leave ==, not ===
      itemIdToCategory[item.id] = category || null;
    });

    const periods = (fp.periods || []).filter((period) => {
      //Only take periods entirely in filter
      return new Date(period.dateStart) >= new Date(filters.start) && new Date(period.dateEnd) <= new Date(filters.end);
    });
    const periodIds = periods.map((period) => period.id);
    const ranges = (fp.ranges || []).filter((range) => (periodIds.includes(range.period)));
    // Map categoryID to an array: {1: [{period: 1, depense: 13000}, {..}], 2: [{..},{..}]}
    const perCatForAllPeriods = {};

    ranges.forEach((range) => {
      (range.itemsInfo || []).forEach((itemInfo) => {
        // Find category of this item
        const category = itemIdToCategory[itemInfo.item];
        const categoryId = category ? category.id : 0;

        if (filters.category && categoryId != filters.category) {
          return;
        }

        if (!perCatForAllPeriods.hasOwnProperty(categoryId)) {
          perCatForAllPeriods[categoryId] = [];
        }

        // Check if we already have a data point for this period in this category
        const existingDataPoint = perCatForAllPeriods[categoryId].find((dataPoint) => dataPoint.period === range.period);
        if (existingDataPoint) {
          existingDataPoint.depense += parseFloat(itemInfo.deductions);
          return;
        }
        perCatForAllPeriods[categoryId].push({
          period: range.period,
          depense: parseFloat(itemInfo.deductions),
        });

      });
    });

    // Get sum of depenses for each period
    const totalPerPeriod = {};
    ranges.map((range) => {
      const periodId = range.period;
      if (!totalPerPeriod[periodId]) {
        totalPerPeriod[periodId] = 0;
      }
      (range.itemsInfo || []).forEach((itemInfo) => {
        totalPerPeriod[periodId] += parseFloat(itemInfo.deductions || 0);
      });
    });

    // Get total depenses for each category
    const totalPerCategory = [];
    Object.keys(perCatForAllPeriods).forEach((categoryId) => {
      let totalForCategory = 0;
      perCatForAllPeriods[categoryId].forEach((dataPoint) => {
        totalForCategory += dataPoint.depense;
      });
      if (totalForCategory > 0) {
        totalPerCategory.push({
          x: `${getCategoryName(categoryId, fp)}: ${totalForCategory}`,
          y: totalForCategory,
          fill: getColorForCategory(categoryId)
        });
      }
    });

    // Find revenues for each period
    const resultsPerPeriod = []; // [{period: periodId, revenuesTotal: 1000, depensesTotal: 2000}]
    ranges.forEach((range) => {
      let revenues = 0;
      let depenses = 0;

      // sum revenues of this period
      (range.revenues || []).forEach((revenueInfo) => {
        if (revenueInfo.amount > 0) {
          revenues += parseFloat(revenueInfo.amount || 0);
        }
      });
      // Sum depenses of this period
      (range.itemsInfo || []).forEach((itemInfo) => {
        depenses += parseFloat(itemInfo.deductions || 0);
      });
      resultsPerPeriod.push({
        period: range.period,
        revenuesTotal: revenues,
        depensesTotal: depenses
      });
    });

    console.log('0resultsPerPeriod', resultsPerPeriod)

    return {
      perCategoryForAllPeriods: perCatForAllPeriods,
      periodsInFilter: periods,
      rangesInFilter: ranges,
      totalPerPeriod,
      totalPerCategory,
      resultsPerPeriod,
    };
  }, [filters, fp]);

  // Get the maximum total depenses/revenue one period
  const maxValues = useMemo(() => {
    let maxDepense = 0;
    let maxRevenue = 0;
    resultsPerPeriod.forEach((result) => {
      if (result.depensesTotal > maxDepense) {
        maxDepense = result.depensesTotal;
      }
      if (result.revenuesTotal > maxRevenue) {
        maxRevenue = result.revenuesTotal;
      }
    });
    return {
      maxDepense,
      maxRevenue,
    }
  }, [resultsPerPeriod]);

  function getTickValues(maxValue) {
    const yTicks = [];
    const max = maxValue || 100;
    const nbTicks = 15;
    let tickStep = Math.ceil(max / nbTicks);
    // Find closest to a divider of 100
    tickStep = Math.ceil(tickStep / 100) * 100;
    for (let i = 0; i <= max; i += tickStep) {
      yTicks.push(i);
    }
    return yTicks;
  }

  console.log('Tot', totalPerCategory);

  const totalDepenses = totalPerCategory.reduce((sum, cat) => sum + cat.y, 0);

  return (
    <div>
      <SectionTitle>
        Analyses
      </SectionTitle>

      <div className="flex flex-wrap gap-4 items-end">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Date de début
          </label>
          <input
            type="date"
            name="start"
            id="start"
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={filters.start}
            onChange={(e) => setFilters({...filters, start: e.target.value})}
          />

        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Date de fin
          </label>
          <input
            type="date"
            name="end"
            id="end"
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={filters.end}
            onChange={(e) => setFilters({...filters, end: e.target.value})}
          />
        </div>
        <div>
          <select
            className="w-full py-1"
            value={filters.category || ''}
            onChange={(e) => setFilters({...filters, category: e.target.value})}
          >
            <option value=''>
              Toutes les catégories
            </option>
            {fp.categories.map((category) => (
              <option
                key={category.id}
                value={category.id}>
                {category.name || '-'}
              </option>
            ))}
          </select>
        </div>

      </div>

      <div>

            <div className="text-2xl mt-4">
              Revenus-dépenses
            </div>

            <VictoryChart theme={VictoryTheme.material} height={250}>
              <VictoryAxis
                tickValues={periodsInFilter.map((pS) => pS.id)}
                tickFormat={periodsInFilter.map((pS) => `${pS.dateStart} - ${pS.dateEnd}`)}
                style={{
                  axisLabel: {fontSize: 6, padding: 1},
                  ticks: {stroke: "grey", size: 2},
                  tickLabels: {fontSize: 4, padding: 1,angle: 45, textAnchor: 'start'},
                }}
              />
              <VictoryAxis dependentAxis crossAxis
                           standalone={false}
                           tickValues={getTickValues(Math.max(maxValues.maxDepense, maxValues.maxRevenue) + 1000)}
                           tickFormat={(x) => (`${x} `)}
                           style={{
                             ticks: {stroke: "grey", size: 2},
                             tickLabels: {fontSize: 6, padding: 1},
                           }}
              />
              <VictoryLine
                data={resultsPerPeriod}
                x="period"
                y="revenuesTotal"
                labels={({ datum }) => datum.revenuesTotal}
                style={{ data: { stroke: "green" } }}
                labelComponent={
                  <VictoryLabel
                    dy={-3}
                    style={{
                      fontSize: 4,
                    }}
                  />
                }
              />
              <VictoryLine
                data={resultsPerPeriod}
                x="period"
                y="depensesTotal"
                labels={({ datum }) => datum.depensesTotal}
                style={{ data: { stroke: "red", }}}
                labelComponent={
                  <VictoryLabel
                    dy={-3}
                    style={{
                      fontSize: 4,
                    }}
                  />
                }
              />
            </VictoryChart>
          </div>

      <div>
        <div className="text-2xl mt-4">
          Dépenses CHF {totalDepenses}
        </div>
      </div>
      <div>
        {hoverInfo &&
          <div className="fixed top-6 p-1 border bg-white z-10"
               style={{top: hoverInfo.top, left: hoverInfo.left}}>
            {hoverInfo.categoryName} <br/>
            CHF {hoverInfo.depense} <br/>
            {(100*hoverInfo.depense/hoverInfo.depenseTotalPeriod).toFixed(1)}% de {hoverInfo.depenseTotalPeriod}
          </div>
        }
        <div>
          <div>
            <VictoryChart
              domainPadding={25}
              height={270}
              theme={VictoryTheme.material}
            >
              <VictoryAxis
                tickValues={periodsInFilter.map((pS) => pS.id)}
                tickFormat={periodsInFilter.map((pS) => `${pS.dateStart} - ${pS.dateEnd}`)}
                style={{
                  axisLabel: {fontSize: 6, padding: 1},
                  ticks: {stroke: "grey", size: 2},
                  tickLabels: {fontSize: 4, padding: 1,angle: 45, textAnchor: 'start'},
                }}
              />

              <VictoryAxis dependentAxis crossAxis
                           standalone={false}
                           tickValues={getTickValues(maxValues.maxDepense)}
                           tickFormat={(x) => (`${x} `)}
                           style={{
                             ticks: {stroke: "grey", size: 2},
                             tickLabels: {fontSize: 6, padding: 1},
                           }}
              />
              <VictoryStack>
                {(Object.keys(perCategoryForAllPeriods || {})).map((categoryId) => {
                  return (
                    <VictoryBar
                      key={categoryId}
                      data={perCategoryForAllPeriods[categoryId]}
                      x="period"
                      y="depense"
                      style={{
                        labels: {fontSize: 6},
                        data: {
                          fill: getColorForCategory(categoryId),
                        }
                      }}
                      events={[{
                        target: "data",
                        eventHandlers: {
                          onMouseOver: (e) => {
                            // Get position on screen from event
                            const pos = {
                              x: e.nativeEvent.clientX,
                              y: e.nativeEvent.clientY,
                            };

                            const catName = getCategoryName(categoryId, fp);

                            return [{
                              mutation: (props) => {
                                setHoverInfo({
                                  depense: props.datum.depense,
                                  period: props.datum.period,
                                  depenseTotalPeriod: totalPerPeriod[props.datum.period],
                                  categoryName: catName,
                                  top: pos.y - 10,
                                  left: pos.x + 40,
                                });
                                return props;
                              }
                            }];
                          },
                          onMouseOut: () => {
                            setHoverInfo(null);
                            return [{
                              mutation: () => {
                                return null;
                              }
                            }];
                          }
                        }
                      }]}
                    />
                  )
                })}
              </VictoryStack>
            </VictoryChart>
          </div>

          <div>
            <VictoryPie
              labelPlacement="parallel"
              data={totalPerCategory}
              theme={VictoryTheme.material}
              radius={50}
              height={250}
              style={{
                labels: {fontSize: 5, padding: 1},
                data: {
                  strokeWidth: 0,
                  fill: ({ datum }) => datum.fill,
                }
              }}
            />
          </div>


        </div>

      </div>

    </div>
  )
}