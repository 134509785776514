
export default function Button(props) {
  const {
    type,
    className="",
    round=false,
    ...restProps
  } = props;

  const shapeClasses = round ? "w-8 h-8" : "px-4 py-2";

  let classes = `${className} ${shapeClasses} font-semibold text-sm text-white rounded-full shadow-sm disabled:bg-zinc-200  `;

  if (type === 'success') {
    classes += 'bg-green-500 hover:bg-green-600';
  } else if (type === 'standard') {
    classes += 'bg-cyan-500 hover:bg-cyan-600';
  } else if (type === 'danger') {
    classes += 'bg-red-500 hover:bg-red-600';
  } else {
    classes += 'bg-slate-200 hover:bg-slate-300 text-slate-500 disabled:text-slate-400';
  }

  return (
    <button className={classes} {...restProps}>
      {props.children}
    </button>
  )
}