import Button from "../../shared/button";

export default function NoFP(props) {
  const {
    loadEmptyFP,
  } = props;

  return (
    <div className="py-5">
      <div className="text-center text-2xl">
        Aucun plan financier n'a été trouvé
      </div>
      <div className="text-center mt-4">
        <Button
          type="success"
          onClick={loadEmptyFP}
        >
          +
          Créer un plan financier
        </Button>
      </div>
    </div>
  )
}