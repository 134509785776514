import round2 from "./round2";

export default function getValidNb(nbString) {
  if (typeof nbString === 'string') {
    nbString = nbString.replace(',', '.');
  }
  let nb = parseFloat(nbString);
  if (isNaN(nb)) {
    return null;
  }
  return round2(nb);
}