import './index.css'
import FinancialPlan from "./components/views/financialPlan";
import Navbar from "./components/shared/navbar";
import AlertPopup from "./components/shared/alertPopup";

export default function App() {

  return (
    <div>
      <AlertPopup />

      <Navbar />
      <div className="min-h-screen">
        <div className="container rounded-md p-4 mt-10 mx-auto shadow-md bg-white">
          <FinancialPlan></FinancialPlan>
        </div>
      </div>

      <div className="text-center hidden">
        <a href="https://www.flaticon.com/free-icons/budget"
           className="text-gray-500 text-sm"
           title="budget icons">Budget icons created by Freepik - Flaticon</a>
      </div>
    </div>
  )
}